@import 'styles/variables';
@import 'styles/mixins';

.tooltip {
  @include position();

  display: inline-block; // Ensure it wraps around inline elements properly
  cursor: pointer;

  &__label {
    @include position(fixed); // Use fixed so it stays in place when scrolling
    max-width: 300px;
    height: fit-content;
    padding: 8px 10px; // Slightly tighter padding for cleaner look
    font-size: $font-size--sm;
    line-height: $font-size--md;
    border-radius: $border-radius--md;
    text-align: center;
    color: $white;
    background-color: $tooltip-bg;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); // Optional: adds a subtle shadow
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1; // Ensures it always floats above other elements
    pointer-events: none; // Avoids accidental interaction

    &::after {
      @include position(absolute, 100%, unset, unset, 50%);

      content: ' ';
      margin-left: -8px;
      border: 8px solid;
      border-color: $tooltip-bg transparent transparent transparent;
    }
  }
}
