@import 'styles/variables';
@import 'styles/mixins';

.place-plots-form {
  @include step-with-image();

  &__action-buttons {
    @include flex(row, center, space-between);

    button {
      min-width: unset;
    }

    > div,
    > button {
      width: 48%;
      margin-bottom: 16px;
      font-size: $font-size--base;
    }

    > div > button {
      width: 100%;
    }
  }
}

.remove-replicates-link {
  margin-top: 8px;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 0;

  a,
  &__button {
    color: $brand-color;
    text-decoration: underline;
    cursor: pointer;
    font-size: $font-size--sm;
    background: none;
    border: none;
    padding: 0;
    font-family: inherit;

    &:hover {
      color: darken($brand-color, 10%);
    }
  }
}
