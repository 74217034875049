@import 'styles/variables';

.file-list {
  margin-bottom: 15px;
  list-style: none;
  padding: 0;
  margin: 0;

  > li:last-child {
    border-bottom: 2px solid $button-bg;
  }
}

.file-groups {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.group-header {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-secondary);
  margin: 0 0 0.25rem 0;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid var(--border-color);
}

.required-files {
  .file-list {
    background-color: var(--background-highlight);
    padding: 0.5rem;
    border-radius: 4px;
  }
}

.optional-files {
  .file-list {
    opacity: 0.85;
  }
}
